import React from "react"
import { Link } from "gatsby"
//
// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <h1>Fernunterricht / Distance Teaching</h1>

    <h2>Auf Deutsch</h2>
    <p>
      <Link to="de/lehrpersonen/1-einleitung">Für Lehrpersonen</Link>
    </p>
    <p>
      <Link to="de/schuelerinnen/1-einleitung">
        Für Schüler*innen
      </Link>
    </p>

    <h2>In English</h2>
    <p>
      <Link to="en/teachers/1-introduction">For teachers</Link>
    </p>
    <p>
      <Link to="en/students/1-introduction">
        For students
      </Link>
    </p>
  </>
)
/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}> */
/*   <Image /> */
/* </div> */

export default IndexPage
